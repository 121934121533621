@tailwind base;
@tailwind components;
@tailwind utilities;

.light {
  background-color: white;
}

.dark {
  background-color: #121212;
}

html {
  transition: background-color 0.3s ease-in-out;
}
